/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, startWith } from 'rxjs';

const errorMessenger$: BehaviorSubject<AsyncError> = new BehaviorSubject<AsyncError>({message: ''});
const successMessenger$: BehaviorSubject<string> = new BehaviorSubject<string>('');

export function setAsyncState<T>(obs$: Observable<T>, defaultResponse: any, error?: string, success?: string): Observable<StateWrapper<T>> {
return obs$.pipe(
    map((response: T) => {
        if(success) triggerSuccessToast(success);
        return wrapResponse<T>(AsyncState.LOADED, response);
    }),
    startWith(wrapResponse(AsyncState.LOADING, defaultResponse)),
    catchError((errorResponse: HttpErrorResponse) => {
        return handleAsyncError(defaultResponse, errorResponse, error);
    })
);
}

export function setAsyncStateNoStart<T>(obs$: Observable<T>, defaultResponse: any, error?: string, success?: string): Observable<StateWrapper<T>> {
    return obs$.pipe(
        map((response: T) => {
            if(success) triggerSuccessToast(success);
            return wrapResponse<T>(AsyncState.LOADED, response);
        }),
        catchError((errorResponse: HttpErrorResponse) => {
            return handleAsyncError(defaultResponse, errorResponse, error);
        })
    );
}

export function handleAsyncError(replacedResponse: any, error: HttpErrorResponse, customMessage?: string): Observable<StateWrapper<any>> {
    const asyncError = {
        message: customMessage ?? error.message,
        status: error.status
    };
    triggerErrorToast(asyncError);
    return of(wrapResponse(AsyncState.ERROR, replacedResponse, asyncError));
}

export function triggerErrorToast(error: AsyncError) {
    errorMessenger$.next(error);
}

export function triggerSuccessToast(message: string) {
    successMessenger$.next(message);
}

export function getErrorMessenger(): BehaviorSubject<AsyncError> {
    return errorMessenger$;
}

export function getSuccessMessenger(): BehaviorSubject<string> {
    return successMessenger$;
}

export enum AsyncState {
    INIT, LOADING, LOADED, ERROR
}

export interface AsyncError {
    message: string,
    status?: number
}

export interface StateWrapper<T> {
    state: AsyncState,
    response: T,
    error?: AsyncError
}

export function wrapResponse<T>(state: AsyncState, response: T, error?: AsyncError): StateWrapper<T> {
return {
    state: state,
    response: response,
    error: error
};
}