/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, type PipeTransform } from '@angular/core';
import { TimeZoneConverter } from 'src/shared/infrastructure/resources/time-zone-converter';
import { PlanningColumnCell } from 'src/shared/models/wrappers/planning-timeline.interface';
import { timeToHourAndMinutes } from 'src/shared/utils/date.utils';

@Pipe({
  name: 'tooltipFormat'
})
export class TooltipFormatPipe implements PipeTransform {

  constructor(private timeZoneConverter: TimeZoneConverter) { }

  transform(value: any, property: keyof PlanningColumnCell): string {
    if (property === 'startDate' || property === 'endDate' || property === 'realLoadingDate') {
      const zonedDate = this.timeZoneConverter.convertUTCDateToRefineryTZ(value);
      if (zonedDate !== 'Invalid Date' && value !== null) {
        return timeToHourAndMinutes(zonedDate);
      } else {
        if(property !== 'realLoadingDate') console.error('Invalid date in tooltipFormat pipe for value: ', value);
        return '';
      }
    } else if (property === 'cellId') {
      return '...' + value?.slice(-6);
    } else {
      return value || '';
    }
  }
}
