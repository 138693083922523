/* eslint-disable @typescript-eslint/no-explicit-any */
import * as moment from 'moment-timezone';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Slot} from '../../../models/macro-slot.interface';
import {
  AbstractControl,
  FormBuilder,
  FormGroup, ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import {
  ITEM_CLASS_TYPE,
  ITEM_OPERATION_TYPE,
  ITEM_TYPE_SET,
  ItemOutputResponse,
  ItemType,
  UnavailabilityCardInput
} from '../../../models/wrappers/unavailabilities-card-input.interface';
import {DockUnavailabilityReason} from 'src/shared/models/dock-unavailability-reason.interface';
import {GradeUnavailabilityReason} from 'src/shared/models/grade-unavailability-reason.interface';
import {subscribeOn} from 'rxjs';
import {Unavailability} from '../../../models/unavailability.interface';
import {OverlayPanel} from 'primeng/overlaypanel';
import { TimeZoneConverter } from '../../../infrastructure/resources/time-zone-converter';
import { dateTimeZoneToUTC } from '../../../utils/date.utils';

interface OverlayParams {
  context: ITEM_CLASS_TYPE, 
  title: string, 
  itemData: any
}
// /!\ this component should be refactored
@Component({
  selector: 'app-calendar-card-unavaibilities',
  templateUrl: './calendar-card-unavaibilities.component.html',
  styleUrls: ['./calendar-card-unavaibilities.component.scss'],
})
export class CalendarCardUnavaibilitiesComponent implements OnInit {

  @Input() unavailabilityCardInput: UnavailabilityCardInput = {
    docks: [],
    grades: [],
    day: new Date()
  };
  @Input() macroSlots: Slot[] | null = [];
  @Input() date: Date | null = null;
  @Input() dockUnavailabilityReasons: DockUnavailabilityReason[] = [];
  @Input() gradeUnavailabilityReasons: GradeUnavailabilityReason[] = [];
  @Output() editUnavaibility: EventEmitter<ItemOutputResponse> = new EventEmitter<ItemOutputResponse>();

  readonly ITEM_TYPE_SET: ITEM_TYPE_SET = ITEM_TYPE_SET;
  readonly ITEM_OPERATION_TYPE: typeof ITEM_OPERATION_TYPE = ITEM_OPERATION_TYPE;
  readonly ITEM_CLASS_TYPE: typeof ITEM_CLASS_TYPE = ITEM_CLASS_TYPE;

  ngOnInit() {
    this.initializeForm();
  }

  constructor(
      private formBuilder: FormBuilder,private timeZoneConverter : TimeZoneConverter
  ) {
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);
    this.minEndDate = new Date(this.minDate);
  }

  timeZone : string = this.timeZoneConverter.timeZone;

  dropdownOptions!: any;
  minDate: Date;
  minEndDate: Date;
  maxEndDate!: Date;
  unavailabilityForm!: FormGroup;

  initializeForm() {
    this.unavailabilityForm = this.formBuilder.group({
      id: [''],
      title: [''],
      fromDatabase: [''],
      toEdit: [''],
      startDate: ['', Validators.required],
      startHour: ['', Validators.required],
      endDate: [''],
      endHour: [''],
      selectedReason: [null, Validators.required],
    }, { validators: this.timeRangeValidator });
  }

  timeRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const startHourControl = control.get('startHour');
    const endHourControl = control.get('endHour');
    const startDateControl = control.get('startDate');
    const endDateControl = control.get('endDate');

    this.validateRangeTime(startHourControl, endHourControl, startDateControl, endDateControl);
    return control.validator;
  };

  validateEndDateTime(): void {
    const endDateControl = this.unavailabilityForm.get('endDate');
    const startHourControl = this.unavailabilityForm.get('startHour');
    const endHourControl = this.unavailabilityForm.get('endHour');
    const startDateControl = this.unavailabilityForm.get('startDate');

    if (endDateControl && endHourControl) {
      const isEndDateEmpty = !endDateControl.value;
      const isEndHourEmpty = !endHourControl.value;
      const resetEndDateErrors = () => {
        endDateControl.setErrors(null);
        endHourControl.setErrors(null);
      };

      if (isEndDateEmpty && isEndHourEmpty) {
        resetEndDateErrors();
      } else if (isEndDateEmpty && !isEndHourEmpty) {
        endDateControl.setErrors({ customError: true });
      } else if (!isEndDateEmpty && isEndHourEmpty) {
        endHourControl.setErrors({ customError: true });
      } else {
        resetEndDateErrors();
      }
    }
    this.validateRangeTime(startHourControl, endHourControl, startDateControl, endDateControl);
  }

  validateRangeTime(startHourControl: any, endHourControl: any, startDateControl: any, endDateControl: any): void {
    const isValidDates = startDateControl && endDateControl && startDateControl.value && endDateControl.value;
    const isValidHours = startHourControl && endHourControl && startHourControl.value && endHourControl.value;

    if (isValidDates && isValidHours) {

      const adjustedStartDate = this.mergeDateAndTime(startDateControl.value, startHourControl.value); moment(startDateControl.value + startHourControl.startHour).format();
      const adjustedEndDate = this.mergeDateAndTime(endDateControl.value, endHourControl.value);

      const isDateRangeValid = new Date(adjustedStartDate).getTime() < new Date(adjustedEndDate).getTime();

      if (!isDateRangeValid) {
        startHourControl.setErrors( { 'invalidTimeRange': true });
        endHourControl.setErrors( { 'invalidTimeRange': true });
      } else {
        startHourControl.setErrors( null);
        endHourControl.setErrors( null);
      }
    }
  }

  resetForm() {
    this.unavailabilityForm.reset();
  }

  onStartDateSelect(date: Date) {
    this.minEndDate = new Date(date);
  }

  onEndDateSelect(date: Date) {
    this.maxEndDate = new Date(date);
  }

  openOverlayWithData(overlayParams: OverlayParams, overlayPanel: any, event: Event) {
    this.resetForm();
    if (overlayParams.context === ITEM_CLASS_TYPE.DOCK) {
      this.dropdownOptions = this.dockUnavailabilityReasons;
    } else if (overlayParams.context === ITEM_CLASS_TYPE.GRADE) {
      this.dropdownOptions = this.gradeUnavailabilityReasons;
    }
    if (overlayParams.itemData[0] && overlayParams.itemData[0].id !== '') {
      this.populateForm(overlayParams.itemData[0], overlayParams.title);
    } else {
      this.populateFormDefault(overlayParams.title);
    }
    overlayPanel.show(event);
  }

  populateForm(data: any, title: string) {

    function formatTime(date: Date): string {
      const hours = String((date.getHours())).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    }

    const startDate = moment.tz(data.startDate, this.timeZone).toDate();
    this.unavailabilityForm.patchValue({
      id: data.id,
      title: title,
      fromDatabase: data.fromDatabase,
      toEdit: data.toEdit,
      startDate: startDate,
      startHour: formatTime(startDate),
      selectedReason: data.reason,
    });

    if (data.endDate) {
      const endDate = moment.tz(data.endDate, this.timeZone).toDate();
      this.unavailabilityForm.patchValue({
        endDate: new Date(endDate),
        endHour: formatTime(endDate),
      });
    }
  }

  populateFormDefault(title: string) {
    this.unavailabilityForm.patchValue({
      id: null,
      title: title,
      fromDatabase: false,
      toEdit: false,
      startDate: null,
      startHour: null,
      selectedReason: null,
    });
  }

  editUnavailability(itemType: ItemType, operationType: ITEM_OPERATION_TYPE, overlay: OverlayPanel): void {
    const formValues = this.unavailabilityForm.value;

      if (this.unavailabilityForm.valid) {
        this.emitItemOutputResponse(this.createItemFromControl(formValues, itemType, operationType));
        this.hideOverlayAndEmitRefresh(overlay);
      } else {
        this.markAllAsTouched(this.unavailabilityForm);
      }
  }


  convertToISOString(date: Date, hours: number, minutes: number): string {
    const mergedDate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0));
    return mergedDate.toISOString().replace(/.\d+.\d+Z$/g, 'Z');
  }


  mergeDateAndTime(dateStr: string, hourStr: string) {
    if ((dateStr && dateStr !== '') && (hourStr && hourStr !== '')) {
      const date: Date = this.extractDate(dateStr);
      const hours: Date = this.extractHours(hourStr);
      return this.convertToISOString(date, hours.getHours(), hours.getMinutes());
    }
    return '';
  }

  createItemFromControl(control: any, itemType: ItemType, operationType: ITEM_OPERATION_TYPE): ItemOutputResponse {
    const startDateFinal = this.mergeDateAndTime(control.startDate, control.startHour);
    const endDateFinal = this.mergeDateAndTime(control.endDate, control.endHour);

    const unavailabilityData: Unavailability = {
      id: control.id,
      [itemType.unavailabilityAttrName]: control.title,
      startDate: dateTimeZoneToUTC(startDateFinal.replace('Z', ''),this.timeZone),
      endDate: dateTimeZoneToUTC(endDateFinal.replace('Z', ''),this.timeZone),
      reason: control.selectedReason,
      toEdit: control.toEdit,
      fromDatabase: control.fromDatabase
    };

    return {
      item: unavailabilityData,
      itemType: itemType,
      operationType: operationType
    };
  }

  emitItemOutputResponse(itemResponse: ItemOutputResponse): void {
    this.editUnavaibility.emit(itemResponse);
  }

  private extractDate(dateStr: string): Date {
    if (typeof dateStr === 'string') {
      const date = new Date();
      // /!\ be careful this part could be different depending on the date format (english, french..)
      const [dayOfMonth, month, year] = dateStr.split('/').map(Number);
      date.setFullYear(year, month-1, dayOfMonth);
      return date;
    }
    return new Date(dateStr);
  }

  private extractHours(hourStr: string): Date {
    if (this.isValidTimeFormat(hourStr)) {
      const date = new Date();
      const [hours, minutes] = hourStr.split(':').map(Number);
      date.setHours(hours, minutes, 0, 0);
      return date;
    } else {
      return new Date(hourStr);
    }
  }

  private isValidTimeFormat(timeString: string): boolean {
    const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    return regex.test(timeString);
  }

  private hideOverlayAndEmitRefresh(overlay: OverlayPanel): void {
    if (overlay) {
      overlay.hide();
    }
  }

  markAllAsTouched(group: FormGroup) {
    Object.values(group.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  protected readonly subscribeOn = subscribeOn;
  protected readonly console = console;
}
