import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal-element',
  templateUrl: './modal-element.component.html',
  styleUrls: ['./modal-element.component.css']
})
export class ModalElementComponent { 
  @Input() id: string = '';
  @Input() header: string = '';
  @Input() modalContentClass: string = '';
}
