import { Pipe, PipeTransform } from '@angular/core';
import { PlanningColumnCell } from 'src/shared/models/wrappers/planning-timeline.interface';

@Pipe({
    name: 'itemFinder'
})
export class ItemFinderPipe implements PipeTransform {
    transform(cells: PlanningColumnCell[], stepHour: string): PlanningColumnCell {
        try {
            const cell = cells.find((cell) => {
                const hour = new Date(cell.startDate).getUTCHours();
                const minutes = new Date(cell.startDate).getUTCMinutes();
                return hour == parseInt(stepHour.split(':')[0]) && minutes === parseInt(stepHour.split(':')[1].replace('Z', ''));
            });
            return cell ?? this.buildEmptyOrderPlanning();
        } catch (ex) {
            console.error(ex);
            return this.buildEmptyOrderPlanning();
        }
    }

    buildEmptyOrderPlanning(): PlanningColumnCell {
        return {
            columnId: '',
            mainText: '',
            secondaryText: '',
            thirdText: '',
            cellId: '',
            startDate: new Date(),
            endDate: new Date(),
            badgedText: '',
            emptyCell: true,
            expectedLoadingDate: new Date(),
            realLoadingDate: null
        };
    }
}