import { Component, Input } from '@angular/core';
import { OpenedDayAroundCurrentDay } from 'src/shared/models/opened-day-around-current-day';
import { PlanningTimeline, PlanningTooltipContent } from 'src/shared/models/wrappers/planning-timeline.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'planning-table',
  templateUrl: './planning-table.component.html',
  styleUrls: ['./planning-table.component.scss'],
})


export class PlanningTableComponent {
  @Input() timelines: PlanningTimeline[] = [];
  @Input() days: OpenedDayAroundCurrentDay = {
    previousDay: '',
    currentDay: '',
    nextDay: ''
  };
  @Input() tooltipContent: PlanningTooltipContent[] = [];

  copyToClipboard(textToCopy: string) {
    navigator.clipboard.writeText(textToCopy);
  }
}